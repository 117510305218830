import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = () => {
  const thisYear = new Date().getFullYear();
  const started = 1998;
  const yearsInBusiness = thisYear - started;
  const yearsInBusinessRoundedDown5 = Math.floor(yearsInBusiness / 5) * 5;
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, paragraphThree, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="About Me" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
                <AboutImg alt="profile picture" filename={img} />
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text">
                  {paragraphOne ||
                    `I have been working in the plastering and rendering trade for over ${yearsInBusinessRoundedDown5} years, specialising in domestic work. I take pride in a clean high level of service, and much of my work comes through word of mouth. My services include:`}
                </p>
                <ul className="about-wrapper__list">
                  <li className="about-wrapper__item">Plaster skimming</li>
                  <li className="about-wrapper__item">Plaster boarding</li>
                  <li className="about-wrapper__item">External rendering</li>
                  <li className="about-wrapper__item">Coving</li>
                  <li className="about-wrapper__item">Plastering over artex</li>
                  <li className="about-wrapper__item">Tiling</li>
                  <li className="about-wrapper__item">Painting and decorating</li>
                </ul>
                <h3 className="about-wrapper__header">Southend and Surrounding Areas</h3>
                <p className="about-wrapper__info-text">
                  {paragraphThree || 'The majority of my work is in and around Essex. The areas I cover include Southend-on-Sea, Westcliff-on-Sea, Leigh-on-Sea, Rayleigh, Benfleet, Wickford and all surrounding areas.'}
                </p>
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
